<template>
  <div class="mt-4">
    <b-table
      :fields="fields"
      :items="itens"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive="sm"
      stacked="md"
      show-empty
      striped
    />
  </div>
</template>
  <script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaDiario',
  props: {
    itens: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'planoConta.codigo',
          label: this.$t('CONTABIL.CONTA_CONTABIL'),
          sortable: true,
        },
        {
          key: 'dataDocumento',
          label: this.$t('CONTABIL.DATA'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'planoConta.descricao',
          label: this.$t('CONTABIL.DESCRICAO'),
          sortable: true,
        },
        {
          key: 'descricaoHistoricoComplemento',
          label: this.$t('CONTABIL.HISTORICO_COMPLEMENTO'),
          sortable: true,
        },
        {
          key: 'descricaoDocumentoReferencia',
          label: this.$t('CONTABIL.DOCUMENTO'),
          sortable: true,
        },
        {
          key: 'valorDebito',
          label: this.$t('CONTABIL.DEBITOS'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatacaoMoedaBRL(value);
          },
        },
        {
          key: 'valorCredito',
          label: this.$t('CONTABIL.CREDITOS'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatacaoMoedaBRL(value);
          },
        },
      ],
    };
  },
  methods: {
    remover(item) {
      this.$emit('remover', item);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  font-size: 12px;
}
</style>
    
<template>
  <modal
    id="modalDiario"
    titulo="Totais diário"
    :exibir="exibir"
    @fechar="fecharModal"
    :campos="$refs"
    :tamanho="'xl'"
    :ocultarRodape="true"
  >      <tabela-totais-diario :itens="itens" />

    <template #modal-footer>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:

// Components:
import Modal from '@/components/modal/Modal';
import TabelaTotaisDiario from '@/views/contabil/relatorios/relatorio-diario/components/TabelaTotaisDiario';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    itens: { type: Array, default: Array },
  },
  components: {
    Modal,
    TabelaTotaisDiario,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>

import ApiService from '@/common/api/api.service';

let apiBasePath = 'PeriodoContabil';
const DiarioService = {
    // FUNÇÕES DE BUSCA:
    async obterDiarioPaginado(filtro, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/listar-por-paginacao-diario`, {
            params: {
                ...filtro,
                ...paginacao
            }
        });
        return data;
    },

    // Relatorios
    async gerarRelatorioDiario(filtro){
        let { data } = await ApiService.get(`${apiBasePath}/relatorio-contabil/diario`, {
            params: {
                ...filtro,
            }
        });
        return data;
    }, 
   
};

export default DiarioService;
